.file-upload-button {
  position: absolute;
}

.file-input {
  position: absolute;
  opacity: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  margin-left: -29%;
  margin-top: -3%;
  cursor: pointer;
}

.file-name {
  margin-left: 20px;
}