@use "@amzn/awsui-design-tokens/polaris.scss" as awsui;

.header {
  position: sticky;
  top: 0;

  padding-left: 32px;
  padding-right: 32px;
  background: awsui.$color-background-home-header;

  .header-title {
    color: awsui.$color-text-home-header-default;
  }
}

.user-avatar-container {
  height: 32px;
  width: 32px;
  border-radius: 3px;
  overflow: hidden;
  vertical-align: middle;
  .user-avatar {
    height: auto;
    max-width: 100%;
    vertical-align: center;
    margin: -5px 0 0;
  }
}
